<template>
  <div class="form-row" v-if="options.length > 0">
    <sgv-input-select
      placeholder="ภาษี vat..."
      class="col-5 col-md-6"
      :options="options"
      select="id"
      v-model="formData.docConfigId"
      :validations="[
        {text: 'required!', value: $v.formData.docConfigId.$dirty && $v.formData.docConfigId.$error}
      ]">
      <template slot-scope="option">
        {{option.name}}
      </template>
    </sgv-input-select>

    <sgv-input-number
      class="col"
      placeholder="ราคา"
      v-model="formData.price"
      :precision="2"
      :validations="[
        {text: 'required!', value: $v.formData.price.$dirty && $v.formData.price.$error}
      ]"
      @keyup.enter.native="createOrder">
    </sgv-input-number>

    <div class="col-auto">
      <button class="btn btn-success" @click="createOrder">
        <fa icon="plus"></fa>
      </button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { round } from 'lodash'
import { CREATE_ORDER } from './graph'


export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    configs: {
      type: Array,
      default () {
        return []
      }
    },
    orders: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      formType: 'vatPrice',
      formData: {
        docConfigId: null,
        price: 0
      }
    }
  },
  validations: {
    formData: {
      docConfigId: { required },
      price: { required },
    }
  },
  computed: {
    options () {
      return this.configs.filter(v => v.type === this.formType)
    }
  },
  methods: {
    calculatePrice () {
      if (!this.formData.docConfigId) return 0

      const {vatPrice} = this.configs.find(v => v.id === this.formData.docConfigId)
      const price =
      this.orders
      .filter(v => ['item', 'exchange', 'expense', 'discountPrice', 'priceDiff'].includes(v.type))
      .reduce((t,v) => round(t + Number(v.totalPrice), 2), 0)

      if (!vatPrice) {
        return 0
      } else if (vatPrice.indexOf('%') !== -1) {
        const vatRate = vatPrice.replace('%', '')
        return round(price * +vatRate / 100, 2)
      } else {
        return round(+vatPrice, 2)
      }
    },
    serializeInput (input) {
      return input
    },
    createOrder () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: CREATE_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input
        }
      })
      .then(res => {
        this.$emit('orderCreated', res.data.createOrder)
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    setDefault () {
      this.formData.docConfigId = null
      this.formData.price = this.calculatePrice()
      this.$v.formData.$reset()
      const config = this.configs.find(v => v.type === this.formType && v.isDefault)
      if (config) this.formData.docConfigId = config.id
    }
  },
  watch: {
    'formData.docConfigId': {
      handler (v) {
        this.formData.price = this.calculatePrice()
        if (!v) {
          this.$v.formData.$reset()
        }
      }
    },
    orders () {
      this.formData.price = this.calculatePrice()
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.setDefault()
    })
  }
}
</script>

<style lang="css" scoped>
</style>
